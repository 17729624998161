import React from "react"
import { Link } from "gatsby"

const LikeTo = ({ header, url, urlName }) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="liketo ">
        <div className="container">
          <div className="lto-content">
            <h4>{header}</h4>
            <Link to={url}>{urlName}</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LikeTo
