import React from "react"
import Layout from "../components/layout/layout"
import PageLayout from "../components/layout/page/page"
import SEO from "../components/seo"
import LikeTo from "../components/layout/page/liketo"
import { StaticImage } from "gatsby-plugin-image"

const About = () => (
  <Layout>
    <SEO title="About" />
    <PageLayout name="About" />
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-md-6 about-l-text">
            <div className="ab-d">
              <h5>Hungry Hippo Solutions</h5>
              <span>Who We Are</span>
              <p>
                <b>Hungry Hippo Solutions</b> exists to give restaurants the
                systems and tools that allows them keep more of their profits.
                We believe that restaurants should maintain greater control over
                their customer data. We believe that regardless of how difficult
                the hospitality industry becomes, success is always possible
                with 3 things in place - systems, tool and vision. Restaurants
                have the odds of success stacked again them. Given the change in
                the marketplace with the introduction of giants like GrubHub,
                PostMates and UberEats, etc. competition isn’t getting any
                easier. If that weren’t hard enough - the Covid pandemic has
                only made a challenging situation even harder. Our goal is to
                give restaurants a better choice for themselves, their employees
                and the success of their businesses.
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <StaticImage
              src="../images/about/about.jpg"
              alt="About"
              placeholder="blurred"
            />
          </div>
          <div className="col-md-5">
            <StaticImage
              src="../images/about/iphone-news.jpg"
              alt="News"
              placeholder="blurred"
            />
          </div>
          <div className="col-md-7 about-r-text">
            <div className="ab-d ab-new">
              <p>
                The world as we know has changed… not just because of the
                Covid-19 but because business as usual is dead and never coming
                back. Some are going to adapt and thrive, other’s won’t be so
                fortunate. The question of the day is, which will you be?
                <br />
                <br />
                I have always been fascinated by the story of David and Goliath.
                You don’t have to be a person faith and to understand the often
                overlooked insight about the story.The information I am about
                share has nothing to do with religious beliefs. It has to do
                with looking a little closer at what looks like an impossible
                situation. This sound much like the one you are facing right
                now.
                <br />
                <br />
                One of the most eye opening books I ever read was by Malcom
                Gladwell’s “David and Goliath”. In it Gladwell makes a lot of
                interesting points but the one that should matter to you is.{" "}
                <br />
                <br />
                “Giants are not what we think they are. The same qualities that
                appear to give them strength are often the sources of great
                weakness.”
                <br />
                <br />
                The source of what makes these companies so important is your
                data. This is why they have grown into multibillion dollar
                giants. If you take that away from them, you render them
                powerless.
                <br />
                <br />
                They are marketing to your customers and charging you
                handsomely. We believe restaurant owners everywhere should just
                stop depending on third party providers. There are plenty of
                stories of restaurant owners who have made that choice and do
                more than fine. Take the story of Casa Vega in California - they
                made a choice to not having their profits, their customer and
                their futures held for a ransom.
              </p>
            </div>
          </div>
          <div className="">
            There is a growing movement of restaurants who aren't willing to
            maintain status quo. They are bucking the normal of tolerating high
            commissions. You know what the surprising thing is? Their customers
            still come to their restaurant. There are some that believe if you
            aren't on a platform customers won't order from you. To each his
            own. Customers come for your service and your food. It doesn't
            matter if you are listed with every major third party company.
            Customers want what you make and how you offer it, it's really just
            that simple.
            <br />
            <br /> Big restaurants like Jimmy John's, Panera Bread and Olive
            Garden along with smaller restaurants have chosen to reject
            third-party platforms. They make this choice for reasons such as
            control, profits and customer satisfaction. Domino's flat out feel
            it's simply not in line with it's business model. They are committed
            to their vision, their desired outcome. My question for you today is
            - what's goal for your restaurant are you committed too? Are you
            just floating along and going with the flow... or are you committed
            to your business model that delivers great service coupled with
            great food?
          </div>
        </div>
        <div className="new-li">
          <LikeTo
            header="Learn how our service can help position you for long term grow without third party companies."
            url="/service"
            urlName="Our Service"
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default About
