import React from "react"

const PageLayout = ({ name }) => {
  return (
    <div className="page-layout  position-relative">
      <div className="container">
        <h4>{name}</h4>
      </div>
      <div class="position-absolute gradient"></div>
    </div>
  )
}

export default PageLayout
